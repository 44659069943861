import api from '@motionelements/core/src/api/base-api-v2';
import { snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';

export const analyzeVisualFeature = async params => api.request('post', '/v2/search/video/analyze-visual', {
  params: api.getLocaleParams(),
  data: params,
});

export const analyzeAuralFeature = async params => api.request('post', '/v2/search/music/analyze-aural', {
  params: api.getLocaleParams(),
  data: params,
});

export const getAnalyzeVisualFeatureResult = async params => api.get('/v2/search/video/analyze-visual', {
  params: {
    ...params,
    ...api.getLocaleParams(),
  },
});

export const getAnalyzeAuralFeatureResult = async params => api.get('/v2/search/music/analyze-aural', {
  params: {
    ...params,
    ...api.getLocaleParams(),
  },
});

export const searchElements = async (mediaType, params, axiosConfig) => {
  params = params || {};
  mediaType = mediaType.replace('_', '-');
  return api.get(`/v2/search/${mediaType}`, {
    ...(axiosConfig || {}),
    params: {
      ...api.getLocaleParams(),
      ...snakeCaseKeys(params),
    },
  });
};
